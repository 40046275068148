/* Hide scrollbar on large screens */
@media (min-width: 1307px) {
  .main {
    overflow-y: hidden;
    min-height: 86vh;
  }
}

/* Enable scrollbar on small screens */
@media (max-width: 1307px) {
  .main {
    overflow: auto;
    min-height: 86vh;
  }
}

.ant-modal-content {
  border-radius: 10px !important;
  padding: 20px 40px !important
}

.ant-modal-body div div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.ant-modal-footer {
  margin: 0 auto !important;
}